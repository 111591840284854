import axios from 'axios';
import api from './api';
import {
	AUTHENTICATED,
	FETCH_ERROR,
	FETCH_SUCCESS,
	LOGIN_ERROR,
	LOGIN_LOADING,
	LOGOUT,
	LOGOUT_LOADING,
	PASSWORD_RESET_LOADING,
	SIGNUP_LOADING,
	UPDATE_PASSWORD_LOADING,
} from '../appRedux/actions/actionTypes';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useLogout } from '../hooks/useLogout';

export const userSignUp = ({
	userFullName,
	userName,
	userMobile,
	userEmail,
	password,
	confirmPassword,
}) => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}/users/signup`, {
		userFullName,
		userName,
		userMobile,
		userEmail,
		password,
		confirmPassword,
	});
};

export const userLogin = ({ email, password }) => {
	return api.post(`/users/login`, { email, password });
};

export const goggleLogin = ({ tokenId }) => {
	return api.post(`/oauth/google`, { tokenId });
};

export const userForgotPassword = ({ email }) => {
	return api.post(`/users/forgotpassword`, { email });
};

export const userResetPassword = (token, { password, confirmPassword }) => {
	return api.patch(`/users/resetpassword/${token}`, {
		password,
		confirmPassword,
	});
};

export const userVerify = (verifyToken) => {
	return axios.get(
		`${process.env.REACT_APP_BASE_URL}/users/verifyemail/${verifyToken}`
	);
};

export const resendEmailFunc = ({ email }) => {
	return axios.post(`${process.env.REACT_APP_BASE_URL}/resendemail`, { email });
};

export const updatePassword = ({
	currentPassword,
	password,
	confirmPassword,
}) => {
	return api.patch(`/users/updatepassword`, {
		currentPassword,
		password,
		confirmPassword,
	});
};

export const allSuccess = (suc) => {
	return suc && suc;
};

export const allError = (err) => {
	return err && err;
};

export const fundWalletError = (err) => {
	return err && err;
};

export const formatError = (error) => {
	return error && error;
};

export const formatSuccess = (success) => {
	return success && success;
};

export const formatErrorLog = (error) => {
	return error;
};
export const formatSuccessLog = (success) => {
	return success;
};

export const failedAction = (message) => {
	return {
		type: FETCH_ERROR,
		payload: message,
	};
};
export const failedActionLog = (message) => {
	return {
		type: LOGIN_ERROR,
		payload: message,
	};
};

export const successAction = (message) => {
	return {
		type: FETCH_SUCCESS,
		payload: message,
	};
};
export const loadingAction = (status) => {
	return {
		type: LOGIN_LOADING,
		payload: status,
	};
};
export const signupLoading = (status) => {
	return {
		type: SIGNUP_LOADING,
		payload: status,
	};
};
export const passwordUpdateLoading = (status) => {
	return {
		type: UPDATE_PASSWORD_LOADING,
		payload: status,
	};
};
export const passwordResetLoading = (status) => {
	return {
		type: PASSWORD_RESET_LOADING,
		payload: status,
	};
};
export const logoutLoading = (status) => {
	return {
		type: LOGOUT_LOADING,
		payload: status,
	};
};

// remember to comment
// export const logout = () => {
// 	const dispatch = useDispatch();
// 	const history = useHistory();
// 	dispatch({ type: LOGOUT });
// 	dispatch(logoutLoading(true));
// 	localStorage.removeItem('user');
// 	localStorage.removeItem('token');
// 	localStorage.removeItem('wallet');
// 	localStorage.removeItem('id');
// 	localStorage.removeItem('fax');
// 	dispatch({ type: AUTHENTICATED, payload: false });
// 	setTimeout(() => {
// 		history.push('/login');
// 		window.location.reload();
// 	}, 4000);
// };

export const tokenExpirationCheck = (dispatch, timer) => {
	setTimeout(() => {
		dispatch(logout());
	}, timer);
};

export const loginStatus = (dispatch) => {
	const token = localStorage.getItem('token');
	let tokenDetails = '';
	if (!token) {
		dispatch(logout());
		return;
	}
	tokenDetails = JSON.parse(token);
	dispatch(successAction(tokenDetails));
};
