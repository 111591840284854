import React from 'react'
import "./loader.css";

const Loader = () => {
    return (
        <div className="loader">
            <div className="overlay"></div>
            <div class="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>

    )
}

export default Loader
