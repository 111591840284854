import axios from "axios";
import { userForgotPassword } from "../../services/auth";
import { FETCH_ERROR, FETCH_MESSAGE, FETCH_SUCCESS, FORGOT_PASSWORD } from "./actionTypes";
export const forgotPassAction = ({email}) => {
    return (dispatch) => {
        dispatch({type: FORGOT_PASSWORD})
        // axios.post(`/users/forgotpassword`, {email})
        userForgotPassword({email}).then(({data}) => {
            console.log('forgot password: ', data);
            if(data.status = 'success'){
                dispatch({type: FETCH_SUCCESS})
                dispatch({
                    type: FETCH_MESSAGE,
                    payload: data.message
                });
            }else {
                dispatch({
                    type: FETCH_ERROR,
                    payload: data.message
                });
            }
        })
    }
}