import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { logoutLoading } from '../services/auth';
import { LOGOUT, AUTHENTICATED } from '../appRedux/actions/actionTypes';

export const useLogout = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const logout = () => {
		dispatch({ type: LOGOUT });
		dispatch(logoutLoading(true));
		localStorage.removeItem('user');
		localStorage.removeItem('token');
		localStorage.removeItem('wallet');
		localStorage.removeItem('id');
		localStorage.removeItem('fax');
		dispatch({ type: AUTHENTICATED, payload: false });
		setTimeout(() => {
			history.push('/login');
			window.location.reload();
		}, 6000);
		console.log('done this!');
	};

	// const dispatch = useDispatch();
	// const history = useHistory();

	// const logout = async () => {
	//   dispatch(logoutLoading(true));

	//   try {
	//     await logout();
	//     dispatch({ type: LOGOUT });
	//     dispatch({ type: AUTHENTICATED, payload: false });
	//     history.push("/login");
	//   } catch (err) {
	//     console.error(err);
	//   } finally {
	//     dispatch(logoutLoading(false));
	//   }
	// };

	return { logout };
};
