import React, { useContext } from 'react';
import { string } from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Row } from 'simple-flexbox';
import { createUseStyles, useTheme } from 'react-jss';
import { SidebarContext } from './../../hooks/useSidebar';
import SLUGS from './../../resources/slugs';
import { IconBell, IconSearch } from './../../assets/icons';
import DropdownComponent from './../dropdown/DropdownComponent';
import User from './../../assets/icon-image/user.png'
import store from '../../../appRedux/utils/store';
import { useDispatch, useSelector } from 'react-redux';
import { AUTHENTICATED, LOGOUT } from '../../../appRedux/actions/actionTypes';
import Loader from '../loading/LoadingComponent';
import { logoutLoading } from '../../../services/auth';

const useStyles = createUseStyles((theme) => ({

    avatar: {
        height: 35,
        width: 35,
        minWidth: 35,
        borderRadius: 50,
        marginLeft: 14,
        border: `1px solid ${theme.color.lightGrayishBlue2}`,
        '@media (max-width: 768px)': {
            marginLeft: 14
        }
    },
    container: {
        height: 40,
        paddingTop: 10
    },
    name: {
        ...theme.typography.itemTitle,
        textAlign: 'center',
        marginRight: '40px',
        '@media (max-width: 768px)': {
            display: 'none'
        }
    },
    separator: {
        borderLeft: `1px solid ${theme.color.lightGrayishBlue2}`,
        marginLeft: 32,
        marginRight: 32,
        height: 32,
        width: 2,
        '@media (max-width: 768px)': {
            marginLeft: 14,
            marginRight: 0
        }
    },
    title: {
        ...theme.typography.title,
        '@media (max-width: 1080px)': {
            marginLeft: 50
        },
        '@media (max-width: 468px)': {
            fontSize: 20
        },
        '@media (max-width: 700px)': {
            marginLeft: '100px'
        }
    },
    iconStyles: {
        cursor: 'pointer',
        marginLeft: 25,
        '@media (max-width: 768px)': {
            marginLeft: 12
        }
    }
}));

function HeaderComponent() {
    const { push } = useHistory();
    const { currentItem } = useContext(SidebarContext);
    const theme = useTheme();
    const classes = useStyles({ theme });
    const state = store.getState();
    const userName = state.auth.authUser.data.user.userName;
    const history = useHistory();
    const dispatch = useDispatch();
    const loading = useSelector(state => state.auth.showLoading);

    let title;
    switch (true) {
        case currentItem === SLUGS.dashboard:
            title = 'Dashboard';
            break;
        case [SLUGS.wallet, SLUGS.fundWallet, SLUGS.transactionHistory].includes(currentItem):
            title = 'Wallet';
            break;
        case [SLUGS.vehicleHistory, SLUGS.newCheck, SLUGS.prevCheck].includes(currentItem):
            title = 'Checkman';
            break;
        case currentItem === SLUGS.settings:
            title = 'Settings';
            break;
        case currentItem === SLUGS.invoice:
            title = 'Invoice';
            break;
        default:
            title = '';
    }

    function onSettingsClick() {
        push(SLUGS.settings);
    }


    const logout = () => {
        dispatch({type: LOGOUT})
        dispatch(logoutLoading(true));
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        localStorage.removeItem('wallet');
        localStorage.removeItem('id');
        dispatch({type: AUTHENTICATED, payload: false})
        setTimeout(() => {
            history.push('/login');
            window.location.reload();
        }, 4000);
    }

    return (
        <Row className={classes.container} vertical='center' horizontal='space-between'>
            <span className={classes.title} id="title">{title}</span>
            {loading && <Loader/>}
            <Row vertical='center'>
                {/* <div className={classes.iconStyles}>
                    <IconSearch />
                </div>
                <div className={classes.iconStyles}>
                    <DropdownComponent
                        label={<IconBell />}
                        options={[
                            {
                                label: 'Notification #1',
                                onClick: () => console.log('Notification #1')
                            },
                            {
                                label: 'Notification #2',
                                onClick: () => console.log('Notification #2')
                            }
                        ]}
                        position={{
                            top: 42,
                            right: -14
                        }}
                    />
                </div> */}
                <div className={classes.separator}></div>
                <DropdownComponent
                    label={
                        <>
                            <span className={classes.name}>{userName}</span>
                            {/* <img
                                src={User}
                                alt='avatar'
                                className={classes.avatar}
                            /> */}
                        </>
                    }
                    options={[
                        {
                            label: 'Settings',
                            onClick: onSettingsClick
                        },
                        {
                            label: 'Logout',
                            onClick: logout
                        }
                    ]}
                    position={{
                        top: 52,
                        right: -4
                    }}
                />
            </Row>
        </Row>
    );
}

HeaderComponent.propTypes = {
    title: string
};

export default HeaderComponent;
