import React, { useState } from 'react'
import { Container, Button, Form } from 'react-bootstrap'
import Reviews from './reviews/index';
import './../../../styles/Homepage/CTA.css';
import { useHistory } from 'react-router';
import store from '../../../appRedux/utils/store';
import { useDispatch, useSelector } from 'react-redux';
import { checkHistory, checkmanLoading, failedCheck, failedCTA, formatCheckmanError } from '../../../services/checkman';
import { AUTHENTICATED, CHECKMAN_SUCCESS, LOGOUT, VEHICLE_LOOKUP } from '../../../appRedux/actions/actionTypes';
import Loader from '../../loader/Loader';
import { logoutLoading } from '../../../services/auth';

const CTA = () => {
    const history = useHistory();
    const [value, setValue] = useState('');
    const state = store.getState();
    const token = localStorage.getItem('token') || state.auth.token;
    const dispatch = useDispatch();
    const loading = useSelector(state => state.checkman.showLoading);
    const errorMessage = useSelector(state => state.checkman.CTAError);

    const logout = () => {
        dispatch({type: LOGOUT});
        dispatch(logoutLoading(true));
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        localStorage.removeItem('wallet');
        localStorage.removeItem('id');
        localStorage.removeItem('fax');
        dispatch({type: AUTHENTICATED, payload: false});
        setTimeout(() => {
            history.push('/login');
            window.location.reload();
        }, 4000);
    }
    
    const handleSubmit = (e) => {
        e.preventDefault();
        if(token){
            handleCheckVin();
        }else{
            const error = formatCheckmanError('Kindly login to check VIN');
            dispatch(failedCTA(error));
            setTimeout(() => {
                dispatch(failedCTA(''))
            }, 3000)
        }
    }

    const handleCheckVin = () => {
        dispatch(checkmanLoading(true));
        console.log(value);
        checkHistory(value).then(data => {
            console.log(data)
            localStorage.setItem('fax', data.data.data.report)
            console.log(data.data.data.report);
            dispatch({type: CHECKMAN_SUCCESS});
            dispatch({type: VEHICLE_LOOKUP, payload: data.data.data.report});
            setTimeout(() => {
                history.push('/carfax');
                window.location.reload();
            }, 2000);
            dispatch(checkmanLoading(true))
        }).catch(error => {
            console.log(error);
            console.log(error.response);
            const errorMessage = formatCheckmanError(error.response.data.message);
            dispatch(failedCTA(errorMessage));
            setTimeout(() => {
                dispatch(failedCheck(''))
            }, 3000)
            if(error.response.data.message == "jwt malformed"){
                window.location.reload()
            }
            if(error.response.data.message == "jwt expired"){
                logout()
            }
            dispatch(checkmanLoading(false))
        })
        setValue('');
    }
    return (
        <Container fluid className="footer-hero">
            <Container fluid className="CTA-con">
                <Container className="text-center">
                    <h1>
                        Stop Guessing, Start Checking
                    </h1>
                    <p>
                        Checkman is here to make sure you're not spending your hard-earned money on a problem.
                    </p>
                    <Form inline className="form-con" onSubmit={handleSubmit}>
                        <Form.Group controlId="formBasicEmail" className='form-group'>

                            <Form.Control type="text" placeholder="VIN/Chassis Number" 
                            className='form-control' 
                            onChange={e => setValue(e.target.value)}/>

                            <Button variant="outline-success" className="btn" type='submit' onClick={handleSubmit}>Check Report</Button>
                        </Form.Group>
                        {errorMessage && <div className="errorM">{errorMessage}</div>}
                        {loading && <Loader/>}
                    </Form>
                </Container>
            </Container>
            <Reviews/>
        </Container>

    )
}

export default CTA
