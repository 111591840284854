import React from 'react';
import { Row } from 'simple-flexbox';
import { createUseStyles, useTheme } from 'react-jss';
import Icon from './../../assets/icon-image/icon.png';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';

const useStyles = createUseStyles((theme) => ({
    container: {
        width: '80%',
        margin: 'auto',
    },
    title: {
        ...theme.typography.cardTitle,
        color: theme.color.grayishBlue,
        marginLeft: 4
    }
}));

function LogoComponent(props) {
    const theme = useTheme();
    const classes = useStyles({ theme });

    return (
        <Row className={classes.container} horizontal='center' vertical='center'>
            <span className={classes.title} onClick={props.onClick}>
                <img src={Icon} alt="Logo" 
                className="img-fluid"
                style = {{
                    width: '100%',
                    height: '100%',
                    objectFit: 'contain',
                    top: '0px',
                    right: '0px',
                    left: '0px',
                    cursor: 'pointer'
                }}
                />
            </span>
        </Row>
    );
}

export default LogoComponent;
