import React, { Component, useEffect } from 'react'
import Navigation from './Navigation';
import 'bootstrap/dist/css/bootstrap.min.css';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {Redirect} from 'react-router'
import Home from '../Pages/Index';
import Login from '../Pages/Login/index';
import SignUp from '../Pages/Signup/index';
import Dashboard from '../Pages/Dashboard';
import Reset from '../Pages/Login/Reset/Reset';
import Carfax from '../../Db/routes/checkman/Carfax';
import "../../styles/Navbar/style.css";
import {useSelector } from 'react-redux';
import Fax from '../Pages/Fax';

const Nav = () => {
    const NavRoute = ({exact, path, component: Component}) => (
      <Route path={path} exact={exact} render={(props) => {
        return (
          <div>
            <Navigation/>
            <Component {...props}/>
          </div>
        )
      }}/>
    )
    const authenticated = useSelector(state => state.auth.token);
    let routes;
    if(authenticated){
      routes = (
        <Router>
          <Switch>
            <NavRoute path="/" exact component={Home}/>
            <Route path='/fund-wallet' component={Dashboard} />
            <Route path='/transaction-history' component={Dashboard} />
            <Route path='/new-check' component={Dashboard} />
            <Route path='/previous-check' component={Dashboard} />
            <Route path='/settings' component={Dashboard} />
            <Route path='/invoice' component={Dashboard} />
            <Route path='/carfax' component={Fax}/>
            <Redirect to='/fund-wallet'/>
          </Switch>
        </Router>
      );
    }else{
      routes = (
                <Router>
                  <Switch>
                    <NavRoute path="/" exact component={Home}/>
                    <NavRoute path="/signup" exact component={SignUp}/>
                    <NavRoute path="/login" exact component={Login}/>
                    <NavRoute path="/reset-password" exact component={Reset}/>
                    <NavRoute path='/users/verifyemail/:token' exact component={Login}/>
                    <NavRoute path='/users/resetpassword/:token' exact component={Reset}/>
                    <Route path='/carfax' component={Fax}/>
                    <Redirect to='/login' />
                  </Switch>
                </Router>
      )
    }
    return (
      <>
        {routes}
      </>
  );
}

export default Nav
