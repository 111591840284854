import Label from './Label';
import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useParams } from 'react-router';
import './../../../styles/Login/index.css';
import { connect, useSelector } from 'react-redux';
import { loginAction } from '../../../appRedux/actions/loginAction';
import { Container, Form, Button, Col, Row, Modal } from 'react-bootstrap';
import { forgotPassAction } from './../../../appRedux/actions/forgotPassAction';
import { useDispatch } from 'react-redux';
import {
	allError,
	failedActionLog,
	failedAction,
	formatErrorLog,
	formatSuccessLog,
	loadingAction,
	resendEmailFunc,
	successAction,
	userForgotPassword,
	userLogin,
	userVerify,
	allSuccess,
} from '../../../services/auth';
import Loader from '../../loader/Loader';
import {
	AUTHENTICATED,
	FETCH_MESSAGE,
	FETCH_SUCCESS,
	FORGOT_MAIL_SENT,
	USER_DATA,
	USER_TOKEN_SET,
	WALLET,
} from './../../../appRedux/actions/actionTypes';
import GoogleLogin from 'react-google-login';
import GoogleLoginComponent from '../Signup/GoogleLoginComponent';

const Login = (props) => {
	const { token } = useParams();
	const [show, setShow] = useState(false);
	const [showEmail, setShowEmail] = useState(false);
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [resetEmail, setResetEmail] = useState('');
	const [resendEmail, setResendEmail] = useState('');
	const history = useHistory();
	const dispatch = useDispatch();
	const errorMessage = useSelector((state) => state.login.errorMessage);
	const successMessage = useSelector((state) => state.login.successMessage);
	const forgotPasswordError = useSelector((state) => state.auth.errorMessage);
	const forgotPasswordSuccess = useSelector(
		(state) => state.auth.successMessage
	);
	const loading = useSelector((state) => state.login.showLoading);
	const verified = useSelector((state) => state.auth.verified);
	const [resendEmailLink, setResendEmailLink] = useState();
	const [goggleToken, setGoggleToken] = useState();
	let mounted = useRef(false);

	useEffect(() => {
		mounted.current = true;
		if (mounted.current) {
			if (token) {
				userVerify(token)
					.then(({ data }) => {
						console.log(data);
						dispatch({ type: USER_VERIFIED, payload: true });
						dispatch({ type: FETCH_MESSAGE, payload: data.message });
						setSuccess(formatSuccessLog(data.data.message));
						dispatch(successAction(success));
					})
					.catch((error) => {
						if (error) {
							console.log(error);
							console.log(error.response);
							dispatch(failedActionLog(err));
							// dispatch(failedActionLog('Kindly verify your email'));
							setErr(formatErrorLog('Kindly verify your email'));
							dispatch(failedActionLog(err));
							// dispatch(failedActionLog('Kindly verify your email'));
							setResendEmailLink('Resend email link');
						} else {
							return;
						}

						// const err = formatErrorLog('Kindly verify your email');
					});
			} else {
				return null;
			}
		}

		return () => {
			mounted.current = false;
		};
	}, []);

	const sendMail = (e) => {
		e.preventDefault();
		setShowEmail(false);
		const mail = {
			email: resendEmail,
		};
		resendEmailFunc(mail)
			.then((data) => {
				console.log(data);
				setSuccess(formatSuccessLog(data.data.message));
				dispatch(successAction(success));
			})
			.catch((error) => {
				console.log(error);
				console.log(error.response);
				setErr(formatErrorLog(error.response.data.message));
				dispatch(failedActionLog(err));
			});
	};

	const handleShow = () => setShow(true);
	const handleClose = () => setShow(false);
	const handleShowEmail = () => setShowEmail(true);
	const handleCloseEmail = () => setShowEmail(false);

	const handleSubmit = (e) => {
		e.preventDefault();
		dispatch(loadingAction(true));
		const user = {
			email: email,
			password: password,
		};
		userLogin(user)
			.then(({ data }) => {
				if (data.status == 'success') {
					dispatch(loadingAction(false));
					localStorage.setItem('token', data.token);
					localStorage.setItem('user', JSON.stringify(data));
					localStorage.setItem('id', data.data.user.id);
					localStorage.setItem(
						'wallet',
						JSON.stringify(data.data.user.wallet[0].amount)
					);
					dispatch({ type: FETCH_SUCCESS });
					dispatch({ type: FETCH_MESSAGE, payload: data.message });
					dispatch({ type: USER_DATA, payload: data });
					dispatch({ type: USER_TOKEN_SET, payload: data.token });
					dispatch({ type: WALLET, payload: data.data.user.wallet[0].amount });
					dispatch({ type: AUTHENTICATED, payload: true });
					history.push('/fund-wallet');
					console.log(data.status);
				} else {
					setMessage(data.message);
				}
			})
			.catch((error) => {
				console.log(error);
				console.log(error.response);
				const err = formatErrorLog(error.response.data.message);
				dispatch(failedActionLog(err));
				dispatch(loadingAction(false));
			});
	};

	const handleForgotPassword = (e) => {
		dispatch(loadingAction(true));
		e.preventDefault();
		setShow(false);
		const user = { email: resetEmail };
		// props.forgotPassAction(user);
		userForgotPassword(user)
			.then(({ data }) => {
				console.log(data);
				dispatch({ type: FORGOT_MAIL_SENT, payload: data.message });
				const message = allSuccess(data.message);
				dispatch(successAction(message));
				dispatch(loadingAction(false));
			})
			.catch((error) => {
				console.log(error);
				console.log(error.response);
				const err = allError(error.response.data.message);
				dispatch(failedAction(err));
				dispatch(loadingAction(false));
			});
	};

	const onSuccess = (response) => {
		console.log('Logged in');
		console.log(response.profileObj);
		console.log(response);
		localStorage.setItem('goggleacct', response.profileObj);
	};

	const onFailure = (response) => {
		console.log(response);
		console.log('Failed');
	};

	return (
		<Container className="login-con">
			<Row>
				<Col> </Col>
				<Col sm={4}>
					<h2>Login</h2>
					<Form onSubmit={handleSubmit}>
						<Form.Group className={Label.classes.formdiv}>
							<Form.Control
								type="email"
								placeholder={Label.plasceholder.email}
								className={Label.classes.form}
								value={email}
								onChange={(e) => setEmail(e.target.value)}
							/>
						</Form.Group>

						<Form.Group className={Label.classes.formdiv}>
							<Form.Control
								type="password"
								placeholder={Label.plasceholder.password}
								className={Label.classes.form}
								value={password}
								onChange={(e) => setPassword(e.target.value)}
							/>
						</Form.Group>

						{errorMessage && <div className="error">{errorMessage}</div>}
						{successMessage && <div className="success">{successMessage}</div>}

						{resendEmailLink && (
							<div className="resend" onClick={handleShowEmail}>
								{resendEmailLink}
							</div>
						)}

						<Form.Group className={Label.classes.formdiv}>
							<Button
								variant="primary"
								type="submit"
								className="btn"
								onSubmit={handleSubmit}
							>
								LOGIN
							</Button>
						</Form.Group>
						{forgotPasswordError && (
							<div className="error">{forgotPasswordError}</div>
						)}
						{forgotPasswordSuccess && (
							<div className="success">{forgotPasswordSuccess}</div>
						)}
					</Form>
					<Button
						variant="secondary"
						className="btn modal-btn"
						onClick={handleShow}
					>
						{Label.text.init}
					</Button>

					{/* <hr />
					<div className="text-center googlediv">
						<GoogleLoginComponent title="Login with Google" />
					</div> */}

					{loading && <Loader />}

					{/* Forgot Password modal */}
					<Container>
						<Modal show={show} onHide={handleClose} className="modal-div">
							<Modal.Body closeButton className="mod-body">
								<span onClick={handleClose}>X</span>

								<h2>Password Reset</h2>
								<p> {Label.text.instruction}</p>

								<Form onSubmit={handleForgotPassword}>
									<Form.Group className={Label.classes.formdiv}>
										<Form.Control
											type="email"
											placeholder={Label.plasceholder.email}
											className={Label.classes.form}
											value={resetEmail}
											onChange={(e) => setResetEmail(e.target.value)}
										/>
									</Form.Group>

									<Form.Group className="form-group">
										<Button
											variant="primary"
											type="submit"
											className="btn"
											onClick={handleForgotPassword}
										>
											SEND
										</Button>
									</Form.Group>
								</Form>
							</Modal.Body>
						</Modal>
					</Container>

					{/* Resend email modal */}
					<Container>
						<Modal
							show={showEmail}
							onHide={handleCloseEmail}
							className="modal-email"
						>
							<Modal.Body closeButton className="mod-body">
								<span onClick={handleCloseEmail}>X</span>

								<h2>Resend Email</h2>

								<Form onSubmit={sendMail}>
									<Form.Group className={Label.classes.formdiv}>
										<Form.Control
											type="email"
											placeholder={Label.plasceholder.email}
											className={Label.classes.form}
											value={resendEmail}
											onChange={(e) => setResendEmail(e.target.value)}
										/>
									</Form.Group>

									<Form.Group className="form-group">
										<Button
											variant="primary"
											type="submit"
											className="btn"
											onClick={sendMail}
										>
											SEND
										</Button>
									</Form.Group>
								</Form>
							</Modal.Body>
						</Modal>
					</Container>
				</Col>
				<Col></Col>
			</Row>
		</Container>
	);
};

export default connect(null, { loginAction, forgotPassAction })(Login);
