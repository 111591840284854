
import { combineReducers } from "redux";
import { routerReducer } from 'react-router-redux'
import signupReducer from "./signupReducer";
import loginReducer from "./loginReducer";
import forgotPassword from "./forgotPassword";
import auth from "./auth";
import walletReducer from "./walletReducer";
import checkmanReducer from "./checkmanReducer";
import {connectRouter} from 'connected-react-router';
export default (history) => combineReducers({
    router: connectRouter(history),
    signup: signupReducer,
    login: loginReducer,
    passwordReset: forgotPassword,
    auth: auth,
    wallet: walletReducer,
    checkman: checkmanReducer
});



