import { FETCH_ERROR, FETCH_SUCCESS, SIGNUP_USER, LOADING, SIGNUP_LOADING } from "../actions/actionTypes";

const initialState = {
    user: {},
    errorMessage: '',
    successMessage: '',
    showLoading: false
}

export default (state = initialState, action) => {
    switch(action.type){
        case SIGNUP_USER: 
        return {
            user: action.payload
        }
        case SIGNUP_LOADING:
            return {
                ...state,
                showLoading: action.payload
            }
        case FETCH_ERROR:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: ''
            }
        case FETCH_SUCCESS:
            return {
                ...state,
                successMessage: action.payload,
                errorMessage: ''
            }
        default: return state;
    }
}