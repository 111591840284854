import 'bootstrap/dist/css/bootstrap.min.css';
import Nav from './components/Navbar/Index';
// import ReactGA from 'react-ga';
import './App.css';
import {Provider} from 'react-redux';
import store from './appRedux/utils/store';

function App() {

  // const TRACKING_ID = 'G-0QBLWWDLXH';
  // const TRACKING_ID = 'UA-201818040-1';
  // ReactGA.initialize(TRACKING_ID);
  // ReactGA.ga('require', 'displayfeatures');
  // ReactGA.pageview(window.location.pathname + window.location.search);
  return (
    <Provider store={store}>
        <Nav/>
    </Provider>
    // <div>
    //   <Nav/>
    // </div>
  );
}

export default App;
