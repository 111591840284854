import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { useHistory } from 'react-router-dom';
import SLUGS from './../../resources/slugs';
import {
    IconAgents,
    IconContacts,
    IconLogout,
    IconOverview,
    IconSettings,
    IconSubscription,
    IconTickets,
    IconSearch
} from './../../assets/icons';
import { convertSlugToUrl } from './../../resources/utilities';
import LogoComponent from './../../components/sidebar/LogoComponent';
import Menu from './../../components/sidebar/MenuComponent';
import MenuItem from './../../components/sidebar/MenuItemComponent';
import { useDispatch, useSelector } from 'react-redux';
import { AUTHENTICATED, LOGOUT } from '../../../appRedux/actions/actionTypes';
import Loader from '../loading/LoadingComponent';
import { logoutLoading } from '../../../services/auth';


const useStyles = createUseStyles({
    separator: {
        borderTop: ({ theme }) => `1px solid ${theme.color.yellow}`,
        marginTop: 16,
        marginBottom: 16,
        opacity: 0.06
    }
});

function SidebarComponent() {
    const { push } = useHistory();
    const theme = useTheme();
    const classes = useStyles({ theme });
    const isMobile = window.innerWidth <= 1080;
    const history = useHistory();
    const dispatch = useDispatch();
    const loading = useSelector(state => state.auth.showLoading);

    // async function logout() {
    //     push(SLUGS.login);
    // }
    const logout = () => {
        dispatch({type: LOGOUT});
        dispatch(logoutLoading(true))
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        localStorage.removeItem('wallet');
        localStorage.removeItem('id');
        localStorage.removeItem('fax');
        dispatch({type: AUTHENTICATED, payload: false})
        setTimeout(() => {
            history.push('/login');
            window.location.reload();
        }, 4000);
    }

    const handleClick = () => {
        dispatch(logoutLoading(true));
        history.push('/');
        window.location.reload();
    }

    function onClick(slug, parameters = {}) {
        push(convertSlugToUrl(slug, parameters));
    }

    return (
        <Menu isMobile={isMobile}>
            <div style={{ paddingTop: 30, paddingBottom: 30 }}>
                <LogoComponent onClick={handleClick}/>
            </div>
            {/* {loading && <Loader/>} */}
            {/* <MenuItem
                id={SLUGS.dashboard}
                title='Dashboard'
                icon={IconOverview}
                onClick={() => onClick(SLUGS.dashboard)}
            /> */}
            <MenuItem
                id={SLUGS.wallet}
                items={[SLUGS.fundWallet, SLUGS.transactionHistory]}
                title='Wallet'
                icon={IconTickets}
            >
                <MenuItem
                    id={SLUGS.fundWallet}
                    title='Fund Wallet'
                    level={2}
                    icon={IconTickets}
                    onClick={() => onClick(SLUGS.fundWallet)}
                />
                <MenuItem
                    id={SLUGS.transactionHistory}
                    title='Transactions'
                    level={2}
                    icon={IconTickets}
                    onClick={() => onClick(SLUGS.transactionHistory)}
                />
            </MenuItem>
            <MenuItem
                id={SLUGS.vehicleHistory}
                items={[SLUGS.newCheck, SLUGS.prevCheck]}
                title='Checkman'
                icon={IconSearch}
            >
                <MenuItem
                    id={SLUGS.newCheck}
                    title='New Search'
                    level={2}
                    icon={IconSearch}
                    onClick={() => onClick(SLUGS.newCheck)}
                />
                <MenuItem
                    id={SLUGS.prevCheck}
                    title='History'
                    level={2}
                    icon={IconSearch}
                    onClick={() => onClick(SLUGS.prevCheck)}
                />
            </MenuItem>
            {/* <div className={classes.separator}></div> */}
            <MenuItem
                id={SLUGS.settings}
                title='Settings'
                icon={IconSettings}
                onClick={() => onClick(SLUGS.settings)}
            />
            <form onSubmit={logout}>
                <MenuItem id='logout' title='Logout' icon={IconLogout} onClick={logout} />
            </form>
        </Menu>
    );
}

export default SidebarComponent;
