import { FORGOT_PASSWORD } from "../actions/actionTypes";

const initialState = {
    user : {},
    errorMessage: '',
    successMessage: '',
    showLoading: false
};

export default (state = initialState, action) => {
    switch(action.type){
        case FORGOT_PASSWORD :
        return {
            user: action.payload
        }
        
        default: return state
    }
}