import React from 'react'
import { Container } from 'react-bootstrap'
import './../../../../styles/Homepage/about.css';
import AboutImg from './AboutImg';

class About extends React.Component{
    constructor(){
        super()
        this.state = {
            images: AboutImg
        }
    }
    render(){
        const text = {
            title: `We verify vehicle history for imported, 
            secondhand cars to see if they have been stolen, 
            flooded, accidented, or have a tampered mileage`,
            
        }
        return (
            <Container fluid className="about-con text-center">
                <Container>
                    <h2> {text.title}</h2>
                    <Container className="about-icons">
                        <AboutImg/>
                    </Container>
                </Container>
            </Container>
        ) 
    }
}

export default About
