const label = {
    email: 'Email address',
    password: 'Password',
    name: 'Fullname'
};
const classes = {
    formdiv: 'form-group',
    form: 'form-control'
};
const plasceholder = {
    email: 'Enter email',
    password: 'Enter password',
    name: 'Enter fullname'
};
const text = {
    init: 'Forgotten Password',
    instruction: ` Enter the email address you used to create your account. 
    If an account exists, we will send you a link to a password reset page`
}
export default {
    label,
    classes,
    plasceholder,
    text
}