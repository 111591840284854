import { LOGIN_USER, LOADING, LOGIN_ERROR, LOGIN_LOADING } from "../actions/actionTypes";

const initialState = {
    token: localStorage.getItem('token'),
    wallet: localStorage.getItem('wallet'),
    id: localStorage.getItem('id'),
    errorMessage: '',
    successMessage: '',
    showLoading: false
}

export default (state = initialState, action) => {
    switch(action.type){
        case LOGIN_USER: 
            return {
                token: action.token,
                wallet: action.wallet,
                id: action.id
            }
        case LOGIN_LOADING:
            return {
                ...state,
                showLoading: action.payload
            }
        case LOGIN_ERROR:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: ''
            }
        
        default: return state;
    }
}