const questions = {
    a: 'What is a Checkman Vehicle History Report?',
    b: 'For whom do we provide Vehicle History Reports?',
    c: 'Does Checkman have information on every vehicle?',
    d: 'What is a VIN?',
    e: 'Where can I ﬁnd my VIN number?',
    f: 'How comprehesive is the Checkman Report?',
};

const answer = {
    a: `Checkman Vehicle History Report is a comprehensive report that shows the history of vehicles from North America. 
        We have billions of records received from thousands of sources across the U.S. and Canada.`,
    b: `Everybody! As long as you are curious about a vehicle’s history; whether you want to purchase, 
        or you are a dealer wanting to get records for your fleet or you already own a vehicle and you want to check its history before you bought it, 
        Checkman is for you.`,
    c: `For every vehicle that is from North America, Europe, Asia, we have comprehensive records on them. 
        Especially North American vehicles.`,
    d: `VIN means Vehicle Identification Number. A vehicle identification number is a unique code, including a serial number, 
        used by the automotive industry to identify individual motor vehicles, towed vehicles, motorcycles, scooters and mopeds, 
        as defined in ISO 3779 and ISO 4030. `,
    e: `The VIN can often be found on the lower-left corner of the dashboard, in front of the steering wheel. 
        You can read the number by looking through the windshield on the driver’s side of the vehicle.

            The VIN may also appear in a number of other locations:
            •	Front of the engine block. This should be easy to spot by popping open the hood and looking at the front of the engine.
            •	Front of the car frame, near the container that holds windshield washer fluid.
            •	Rear-wheel well. Try looking up, directly above the tire.
            •	Inside the driver-side doorjamb. Open the door and look underneath where the side-view mirror would be located if the door was shut.
            •	Driver-side doorpost. Open the door and look near the spot where the door latches, not too far from the seatbelt return.
            •	Underneath the spare tire.
            `,
    f: `The Checkman report is robust and is sourced from some of the best global partners like CARFAX. 
    We provide direct carfax reports at competitive prices while also providing options for vehicle history reports for Japanese, 
    Korean and European cars as well`

};

export default {
    questions,
    answer
}