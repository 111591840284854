import React from 'react'
import { Accordion, Card } from 'react-bootstrap'
import { useState } from 'react';


const AccordionComponent = (props) => {
    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click);
    const toggleIcon = {
        close: '+',
        open: '-'
    }
    const {eventKey, question, answer} = props;
    return (
        <Card>
            <Card.Header>
                <Accordion.Toggle as={Card.Header} 
                variant="link" 
                eventKey={eventKey} 
                className='accord-tog' 
                onClick={handleClick}>

                    <h4>{question}</h4>
                    <h4 className='toggle-indicator'>
                        {click ? toggleIcon.open : toggleIcon.close}
                    </h4>

                </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey={eventKey}>
                <Card.Body>
                    <p>{answer}</p>
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    )
}

export default AccordionComponent
