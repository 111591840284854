import React, { useRef, useEffect } from 'react'
import {useReactToPrint} from 'react-to-print'
import { Button } from 'react-bootstrap';
import store from '../../appRedux/utils/store'
import Loader from './../loader/Loader'
import { useDispatch, useSelector } from 'react-redux';
import { checkmanLoading } from '../../services/checkman';
import jsPDF from 'jspdf';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import htmlToPdfmake from 'html-to-pdfmake';
import htm2canvas from 'html2canvas';
import html2canvas from 'html2canvas';


const Fax = () => {

    const dispatch = useDispatch();
    const loading = useSelector(state => state.checkman.showLoading)
    const state = store.getState();
    const carfax = state.checkman.carfax;
    console.log(carfax)

    const loadReport = () => {
        dispatch(checkmanLoading(true));
        setTimeout(() => {
            dispatch(checkmanLoading(false))
        }, 1000)
    }
    const componentRef = useRef();
    const printDocument = useReactToPrint({
        content: () => componentRef.current,
    });

    // const printDocument = () => {
    //     const doc = new jsPDF();
    //     const report = document.getElementById('report');
    //     const html = htmlToPdfmake(report.innerHTML);
    //     const documentDefinition = {content: html};
    //     pdfMake.vfs = pdfFonts.pdfMake.vfs;
    //     pdfMake.createPdf(documentDefinition).open();
    // }

    // const printDocument = () => {
    //     const report = document.getElementById('report');
    //     html2canvas(report).then((canvas) => {
    //         const imgData = canvas.toDataURL('image/png');
    //         const pdf = new jsPDF();
    //         pdf.addImage(imgData, 'PNG', 0, 0);
    //         pdf.save('Carfax.pdf');
    //     })
    // }

    // const printDocument = () => {
    //     const doc = new jsPDF("p", "pt", "a4");
    //     doc.html(document.getElementById('report'), {
    //         callback: function(pdf){
    //             pdf.save('document.pdf')
    //         }
    //     });
    // }

    useEffect(() => {
        loadReport()
    },[])

    return (
        <>
            {loading && <Loader/>}
            <div style={{fontSize:'12px'}} dangerouslySetInnerHTML={{ __html: carfax }} ref={componentRef}/>  
            {/* <div style={{fontSize:'12px'}} dangerouslySetInnerHTML={{ __html: carfax }} id="report"/>   */}
            <Button type='submit'
                style={{
                    position: 'fixed',
                    bottom: '40px',
                    right: '10px',
                    zIndex: '1',
                    backgroundColor: '#3777BC',
                    border: '1px solid #3777BC'
                }}
                onClick = {printDocument}
            >
                Download
            </Button>
        </>
    )
}

export default Fax
