import React, { useState } from 'react';
import {
	Container,
	Jumbotron,
	Form,
	FormControl,
	Button,
	Row,
	Col,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
	AUTHENTICATED,
	CHECKMAN_SUCCESS,
	LOGOUT,
	VEHICLE_LOOKUP,
} from '../../../appRedux/actions/actionTypes';
import store from '../../../appRedux/utils/store';
import { logoutLoading } from '../../../services/auth';
import {
	approvedCheck,
	checkHistory,
	checkmanLoading,
	failedCheck,
	formatCheckmanError,
	formatCheckmanSuccess,
	reportAvailability,
} from '../../../services/checkman';
import Loader from '../../loader/Loader';
import './../../../styles/Homepage/hero.css';

const HeroSection = () => {
	const state = store.getState();
	const token = localStorage.getItem('token') || state.auth.token;
	const history = useHistory();
	const dispatch = useDispatch();
	const [value, setValue] = useState('');
	const loading = useSelector((state) => state.checkman.showLoading);
	const errorMessage = useSelector((state) => state.checkman.errorMessage);
	const successMessage = useSelector((state) => state.checkman.successMessage);
	const [checkVin, setVin] = useState(false);
	const [checkVehicle, setVehicle] = useState(false);

	const logout = () => {
		dispatch({ type: LOGOUT });
		dispatch(logoutLoading(true));
		localStorage.removeItem('user');
		localStorage.removeItem('token');
		localStorage.removeItem('wallet');
		localStorage.removeItem('id');
		localStorage.removeItem('fax');
		dispatch({ type: AUTHENTICATED, payload: false });
		setTimeout(() => {
			history.push('/login');
			window.location.reload();
		}, 4000);
	};

	const checkVinNumber = () => {
		setVehicle(false);
		setVin(true);
	};

	const checkVeh = () => {
		setVin(false);
		setVehicle(true);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if (checkVin === true) {
			if (token) {
				handleAvailcheck();
			} else {
				const error = formatCheckmanError(
					'Kindly login to check VIN availability'
				);
				dispatch(failedCheck(error));
				setTimeout(() => {
					dispatch(failedCheck(''));
				}, 3000);
			}
		} else if (checkVehicle === true) {
			if (token) {
				handleCheckVin();
			} else {
				const error = formatCheckmanError('Kindly login get vehicle report');
				dispatch(failedCheck(error));
				setTimeout(() => {
					dispatch(failedCheck(''));
				}, 4000);
			}
		}
	};

	const handleAvailcheck = () => {
		console.log(checkVin);
		dispatch(checkmanLoading(true));
		console.log(value);
		reportAvailability(value)
			.then((data) => {
				console.log(data);
				console.log(data.data.message);
				const successMessage = formatCheckmanSuccess(data.data.message);
				dispatch(approvedCheck(successMessage));
				dispatch(checkmanLoading(false));
			})
			.catch((error) => {
				console.log(error);
				console.log(error.message);
				const errorMessage = formatCheckmanError(error.response.data.message);
				dispatch(failedCheck(errorMessage));
				setTimeout(() => {
					dispatch(failedCheck(''));
				}, 3000);
				if (error.response.data.message == 'jwt malformed') {
					window.location.reload();
				}
				if (error.response.data.message == 'jwt expired') {
					logout();
				}
				dispatch(checkmanLoading(false));
			});
	};

	const handleCheckVin = () => {
		console.log(checkVehicle);
		dispatch(checkmanLoading(true));
		console.log(value);
		checkHistory(value)
			.then((data) => {
				console.log(data);
				localStorage.setItem('fax', data.data.data.report);
				console.log(data.data.data.report);
				dispatch({ type: CHECKMAN_SUCCESS });
				dispatch({ type: VEHICLE_LOOKUP, payload: data.data.data.report });
				const successMessage = 'Approved';
				dispatch(approvedCheck(successMessage));
				setTimeout(() => {
					history.push('/carfax');
					window.location.reload();
				}, 2000);
				dispatch(checkmanLoading(true));
			})
			.catch((error) => {
				console.log(error);
				console.log(error.response);
				const errorMessage = formatCheckmanError(error.response.data.message);
				dispatch(failedCheck(errorMessage));
				setTimeout(() => {
					dispatch(failedCheck(''));
				}, 3000);
				if (error.response.data.message == 'jwt malformed') {
					window.location.reload();
				}
				if (error.response.data.message == 'jwt expired') {
					logout();
				}
				dispatch(checkmanLoading(false));
			});
		setValue('');
	};
	return (
		<Jumbotron fluid className="jumbotron">
			{loading && <Loader />}
			<Container>
				<h1>Don’t buy a problem!</h1>
				<h5>
					Save millions of naira; avoid stories that touch the heart & get your
					comprehensive Vehicle Report for as low as NGN 10,000
				</h5>
				<Form inline className="form-con" onSubmit={handleSubmit}>
					<Form.Group controlId="formBasicEmail" className="form-group">
						<Form.Control
							type="text"
							placeholder="VIN/Chassis Number"
							className="form-control"
							value={value}
							onChange={(e) => setValue(e.target.value)}
						/>
					</Form.Group>
					{errorMessage && <div className="errorM">{errorMessage}</div>}
					{successMessage && <div className="successM">{successMessage}</div>}

					<Form.Group className="form-btn">
						<Button
							variant="outline-success"
							className="btn"
							type="submit"
							onClick={checkVinNumber}
						>
							Check Availability
						</Button>
						<Button
							variant="outline-success"
							className="btn"
							type="submit"
							onClick={checkVeh}
						>
							Get Comprehensive Vehicle Report
						</Button>
					</Form.Group>
				</Form>
			</Container>
		</Jumbotron>
	);
};

export default HeroSection;
