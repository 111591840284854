import React from 'react'
import {Nav, Navbar, Container, NavDropdown} from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import { useState } from 'react'
import { Link } from 'react-router-dom'
import LogoImg from "./../../assests/Checkman Web Assets/Checkman-Logo---Black.png";
import * as BsIcons from 'react-icons/bs';
import { connect, useDispatch, useSelector } from 'react-redux';
import {useHistory} from 'react-router'
import { checkHistory, checkmanLoading, failedCheck, failedCheckSample } from '../../services/checkman';
import { AUTHENTICATED, CHECKMAN_SUCCESS, LOGOUT, VEHICLE_LOOKUP } from '../../appRedux/actions/actionTypes';
import Loader from '../loader/Loader';
import store from '../../appRedux/utils/store';
import { logoutLoading } from '../../services/auth';
import nigerianFlag from './../../assests/img/flag.png'

const Navigation = () => {
    const [expanded, setExpanded] = useState(false);
    const [click, setClick] = useState(false);


    const handleMenuVisibility = () => {
        setExpanded(expanded ? false : "expanded");
        setClick(!click);

    }
    const collapseMenu = () => {
        setExpanded(false);
        setClick(false);

    }
    const menuIcon = {
        close: <BsIcons.BsJustifyRight onClick={handleMenuVisibility} className="toggle-icon"/>,
        open: <BsIcons.BsX onClick={handleMenuVisibility} className="toggle-icon"/>
    };

    const authenticated = useSelector(state => state.auth.token);
    const dispatch = useDispatch();
    const history = useHistory();
    const loading = useSelector(st => st.checkman.showLoading);
    const errorMessage = useSelector(state => state.checkman.sampleError);
    const state = store.getState();
    const token = localStorage.getItem('token') || state.auth.token;
    const value = '12345678901234567';

    const logout = () => {
        dispatch({type: LOGOUT});
        dispatch(logoutLoading(true));
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        localStorage.removeItem('wallet');
        localStorage.removeItem('id');
        localStorage.removeItem('fax');
        dispatch({type: AUTHENTICATED, payload: false});
        setTimeout(() => {
            history.push('/login');
            window.location.reload();
        }, 4000);
    }

    const smapleReport = () => {
        if(token){
            collapseMenu();
            console.log(value)
            dispatch(checkmanLoading(true));
            checkHistory(value).then(data => {
                localStorage.setItem('fax', data.data.data.report);
                console.log(data.data.data.report);
                dispatch({type: CHECKMAN_SUCCESS})
                dispatch({type: VEHICLE_LOOKUP, payload: data.data.data.report});
                setTimeout(() => {
                    history.push('/carfax');
                    window.location.reload();
                }, 1000);
                dispatch(checkmanLoading(true));
            }).catch(error => {
                console.log(error);
                if(error.response.data.message == "jwt malformed"){
                    history.push('/login')
                    window.location.reload()
                }
                if(error.response.data.message == "jwt expired"){
                    logout()
                }
                dispatch(checkmanLoading(false))
                console.log(error.response)
            })
        }else{
            dispatch(failedCheckSample('Kindly login to view sample report'))
            // setTimeout(() => {
            //     history.push('/login');
            // }, 3000);
            setTimeout(() => {
                dispatch(failedCheckSample(''))
            }, 4000)
        }

    }



    return (
        <>
        <Container fluid className="con-fluid">
            {loading && <Loader/>}
            <Container className='container con-af-fluid'>
                <Navbar expand='lg' expanded= {expanded} className="Navbar">
                    <Navbar.Brand as={Link} to='/' className="navbar-brand" onClick={collapseMenu}> 
                        <img src={LogoImg} alt='logo' className="img-fluid"/> 
                    </Navbar.Brand>

                    {click ? menuIcon.open : menuIcon.close}

                    
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className=" nav">
                            {authenticated && (
                                <>
                                <Nav.Link as={Link} to='#' onClick={smapleReport}>Sample Report</Nav.Link>
                                <Nav.Link as={Link} to='/fund-wallet' onClick={collapseMenu} className="nav-emp">Dashboard</Nav.Link>
                                <Nav.Link as={Link} to='#' onClick={logout} className="nav-emp">Log out</Nav.Link>
                                </>
                            )}

                            {!authenticated && (
                                <>
                                    <Nav.Link as={Link} to='#' onClick={smapleReport}>Sample Report</Nav.Link>
                                    <Nav.Link as={Link} to='/login' onClick={collapseMenu} className="nav-emp">Login</Nav.Link>
                                    <Nav.Link as={Link} to='/signup' onClick={collapseMenu} className="nav-emp">Sign Up</Nav.Link>
                                </>
                            )}
                            <div className="flag">
                                <img src={nigerianFlag} alt="nigerian-flag" className="img-fluid"/>
                            </div>
                        </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </Container>
        </Container>
        {errorMessage && <div className="errorM">{errorMessage}</div>}
        </>



    )
}

// export default Navigation
export default connect(null, {checkHistory})(Navigation)
