import {
	FETCH_BALANCE,
	FUND_WALLET,
	USER_TRANSACTION,
	WALLET,
	LOADING,
	WALLET_LOADING,
	WALLET_LOADING_HISTORY,
	PAYSTACK,
	PAYSTACK_URL,
	USER_TRANSACTION_ERROR,
	FETCH_ERROR,
	FULLSCREEN,
	FETCH_WALLET_ERROR,
	PACKAGE,
} from '../actions/actionTypes';

const initialState = {
	user: {},
	wallet: localStorage.getItem('wallet'),
	invoice: localStorage.getItem('invoice'),
	walletBalance: '',
	package: '',
	transaction: {},
	showLoading: false,
	errorMessage: '',
	paystack: {},
};

export default (state = initialState, action) => {
	switch (action.type) {
		case WALLET:
			return {
				...state,
				user: action.payload,
			};
		case FETCH_BALANCE:
			return {
				...state,
				walletBalance: action.payload,
			};
		case PAYSTACK:
			return {
				...state,
				paystack: action.payload,
			};
		case PAYSTACK_URL:
			return {
				...state,
				paystack: action.payload,
			};
		case FUND_WALLET:
			return {
				...state,
				user: action.payload,
				wallet: action.payload,
			};
		case USER_TRANSACTION:
			return {
				...state,
				transaction: action.payload,
			};
		case FETCH_WALLET_ERROR:
			return {
				...state,
				errorMessage: action.payload,
			};
		case WALLET_LOADING:
			return {
				...state,
				showLoading: action.payload,
			};
		case WALLET_LOADING_HISTORY:
			return {
				...state,
				showLoading: action.payload,
			};
		case PACKAGE:
			return {
				...state,
				package: action.payload,
			};
		default:
			return state;
	}
};
