import axios from 'axios';
import { useSelector } from 'react-redux';
import store from '../appRedux/utils/store';

const state = store.getState();
const token = localStorage.getItem('token') || state.auth.token;

const api = axios.create({
	// baseURL: 'https://checkman-prod.herokuapp.com/api/v1',
	// baseURL: 'http://localhost:2022/api/v1',
	baseURL: `${process.env.REACT_APP_BASE_URL}`,
	headers: {
		Authorization: `Bearer ${token}`,
		withCredentials: true,
		'Content-Type': 'application/json',
	},
});

// api.defaults.withCredentials = true;

export default api;
