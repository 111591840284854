export default {
    // private
    dashboard: '/dashboard',
    wallet: '/wallet',
    fundWallet: '/fund-wallet',
    transactionHistory: '/transaction-history',
    vehicleHistory: '/vehicle-history',
    newCheck: '/new-check',
    prevCheck: '/previous-check',
    settings: '/settings',
    carfax: '/carfax',
    invoice: '/invoice',
    // auth
    home: '/',
    login: '/login',
    signup: '/signup',
    forgotPassword: '/forgot_password'
};
