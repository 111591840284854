import React, {useState} from 'react'
import Label from './../Label';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { useDispatch } from "react-redux";
import './../../../../styles/Login/index.css';
import { resetAction } from '../../../../appRedux/actions/resetAction';
import { Container, Form, Button, Col, Row } from 'react-bootstrap';
import { allError, failedAction, passwordResetLoading, userResetPassword } from '../../../../services/auth';
import { FETCH_MESSAGE, FETCH_SUCCESS, PASSWORD_RESET } from '../../../../appRedux/actions/actionTypes';
import Loader from '../../../loader/Loader';


const Reset = (props) => {
    const error = useSelector(state => state.auth.errorMessage);
    const loading = useSelector(state => state.auth.showLoading);
    const [success, setSuccess] = useState(null);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConPassword] = useState('');
    const history = useHistory();
    const {token} = useParams();
    const dispatch = useDispatch()

    const handleSubmit = (e) => {
        dispatch(passwordResetLoading(true));
        e.preventDefault();
        // history.push('/login');
        const user = {
            password, 
            confirmPassword
        };
        if(token){
            // props.resetAction(token,user);
            userResetPassword(token, user).then(({data}) => {
                console.log("Reset: ", data);
                dispatch({type: FETCH_SUCCESS});
                dispatch({type: FETCH_MESSAGE, payload: data.message});
                dispatch({type: PASSWORD_RESET, payload: true})
                history.push('/login');
                dispatch(passwordResetLoading(false));
            }).catch(error => {
                console.log(error);
                console.log(error.response);
                const errorMessage = allError(error.response.data.message);
                dispatch(failedAction(errorMessage));
                dispatch(passwordResetLoading(false));

            })

        }
    }
    return (
        <Container className="login-con">
            {loading && <Loader/>}
            <Row>
                <Col></Col>
                <Col sm={4}>
                    <h2>Password Reset</h2>
                    <Form onSubmit={handleSubmit}>

                        <Form.Group className={Label.classes.formdiv}>

                            <Form.Control type="password" 
                            placeholder='Password'
                            className='form-control'
                            value={password} 
                            onChange={(e) => setPassword(e.target.value)}
                            />

                        </Form.Group>
                        <Form.Group className={Label.classes.formdiv}>

                            <Form.Control type="password" 
                            placeholder='Confirm password'
                            className='form-control'
                            value={confirmPassword} 
                            onChange={(e) => setConPassword(e.target.value)}
                            />

                        </Form.Group>
                        {error && <div className="error">{error}</div>}
                        {success && <div className="error">{success}</div>}


                        <Form.Group  className='form-group'>
                            <Button variant="primary" type="submit" className="btn" onSubmit={handleSubmit}>
                                LOGIN
                            </Button>
                        </Form.Group>
                    </Form>
                </Col>
                <Col></Col>
            </Row>

        </Container>
    )
}

export default connect(null, {resetAction})(Reset)

