import React from 'react'
import { Button } from 'react-bootstrap'
import { Card } from 'react-bootstrap'
import { Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import './../../../../styles/Homepage/prices.css';
import icon1 from './../../../../assests/Checkman Web Assets/Checkman-Logo---color.png';
import { useHistory } from 'react-router';


const CardItem = (props) => {
    
    return (
        <>
            <Nav.Link 
                as={Link} to={props.path}
                className="price-link">
                <Card className={props.className}>
                    <Card.Body>
                        <p className="recommend">{props.recommend}</p>
                        <h5>{props.type}</h5>
                        <h2> &#8358;{props.price}</h2>
                        <small>{props.specification}</small>
                        <p className="package">{props.package}</p>
                        <div className="logo">
                            <img className="img-fluid" src={icon1} alt="logo"/>
                        </div>
                        <Button className="btn" type="submit" onClick={props.onClick}>BUY NOW</Button>
                    </Card.Body>
                </Card>
            </Nav.Link>
            
        </>
    )
}

export default CardItem
