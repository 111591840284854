import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// import {Provider} from 'react-redux';
// import store from './appRedux/utils/store';
// localStorage.clear();

ReactDOM.render(
        <App />
, document.getElementById('root'));
