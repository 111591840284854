import React from 'react'
import { Accordion, Container } from 'react-bootstrap'
import './../../../../styles/Homepage/questions.css';
import AccordionComponent from './AccordionComponent';
import content from './content';


const AskedQuestions = () => {

    const vin = `
    The VIN can often be found on the lower-left corner of the dashboard, in front of the steering wheel. 
    You can read the number by looking through the windshield on the driver’s side of the vehicle.

    The VIN may also appear in a number of other locations:
    •	Front of the engine block. 
        This should be easy to spot by popping open the hood and looking at the front of the engine.
    •	Front of the car frame, near the container that holds windshield washer fluid.
    •	Rear-wheel well. Try looking up, directly above the tire.
    •	Inside the driver-side doorjamb. 
        Open the door and look underneath where the side-view mirror would be located if the door was shut.
    •	Driver-side doorpost. 
        Open the door and look near the spot where the door latches, not too far from the seatbelt return.
    •	Underneath the spare tire.
                `

    return (
        <Container fluid className="freqQ">
            <Container>
                <h1 className="text-center">FAQs</h1>
                <Accordion>
                    <AccordionComponent
                        eventKey = '0'
                        question = {content.questions.a}
                        answer = {content.answer.a}
                    />
                    <AccordionComponent
                        eventKey = '1'
                        question = {content.questions.b}
                        answer = {content.answer.b}
                    />
                    <AccordionComponent
                        eventKey = '2'
                        question = {content.questions.c}
                        answer = {content.answer.c}
                    />
                    <AccordionComponent
                        eventKey = '3'
                        question = {content.questions.d}
                        answer = {content.answer.d}
                    />
                    <AccordionComponent
                        eventKey = '4'
                        question = {content.questions.e}
                        answer = {(<pre>{vin}</pre>)}
                    />
                    <AccordionComponent
                        eventKey = '5'
                        question = {content.questions.f}
                        answer = {content.answer.f}
                    />
                </Accordion>
            </Container>
        </Container>
    )
}

export default AskedQuestions
