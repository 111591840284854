import React from 'react'
import HeroSection from './Index/Hero'
import About from './Index/about/index'
import Partners from './Index/Partners'
import AskedQuestions from './Index/Faqs/index'
import CTA from './Index/CTA'
import Footer from './Index/footer/index'
import Prices from './Index/Prices'

const Home = () => {
    return (
        <div>
            <HeroSection/>
            <Partners/>
            <About/>
            <Prices/>
            <AskedQuestions/>
            <CTA/>
            <Footer/>
        </div>
    )
}

export default Home
