import { VEHICLE_LOOKUP, CHECKMAN_ERROR, LOADING, CHECKMAN_SUCCESS, CHECKMAN_LOADING, CHECKMAN_HISTORY, CHECKMAN_LOADING_HISTORY, FULLSCREEN, MINISCREEN, CHECKMAN_SAMPLE_ERROR, CTA_ERROR, CTA_SUCCESS, CARFAX_LOADING } from "../actions/actionTypes";

const initialState = {
    user : {},
    vinData: {},
    carfax: localStorage.getItem('fax'),
    errorMessage: '',
    successMessage: '',
    sampleError: '',
    CTAError: '',
    CTASuccess: '',
    showLoading: false,
    loadingCarfax: false,
    miniScreen: true
};

export default (state = initialState, action) => {
    switch(action.type){
        case VEHICLE_LOOKUP :
            return {
                user: action.payload,
                carfax: action.payload
            }
        case CHECKMAN_HISTORY: 
            return {
                user: action.payload
            }
        case CHECKMAN_LOADING:
            return {
                ...state,
                showLoading: action.payload
            }
        case CHECKMAN_LOADING_HISTORY:
            return {
                ...state,
                showLoading: action.payload
            }
        case CARFAX_LOADING:
            return {
                ...state,
                loadingCarfax: action.payload
            }
        case CHECKMAN_SUCCESS:
            return {
                ...state,
                successMessage: action.payload,
                errorMessage: '',
            }
        case CHECKMAN_ERROR:
            return {
                ...state,
                errorMessage: action.payload,
                successMessage: '',
            }
        case CHECKMAN_SAMPLE_ERROR:
            return {
                ...state,
                errorMessage: '',
                successMessage: '',
                sampleError: action.payload
            }
        case CTA_ERROR:
            return {
                ...state,
                CTASuccess: '',
                CTAError: action.payload
            }
        case CTA_SUCCESS:
            return {
                ...state,
                CTAError: '',
                CTASuccess: action.payload
            }
        case MINISCREEN: 
            return {
                ...state,
                miniScreen: action.payload
            }
        default: return state
    }
}