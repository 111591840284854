import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { Column, Row } from 'simple-flexbox';
import { SidebarComponent, SidebarContext } from './../components/sidebar';
import HeaderComponent from './../components/header/HeaderComponent';
import PrivateRoutes from './PrivateRoutes';
import { useSelector } from 'react-redux';
import Loader from '../components/loading/LoadingComponent';

const useStyles = createUseStyles({
    container: {
        height: '100vh',
        overflowY: 'auto',
        // minHeight: 850
        '@media (max-width: 1080px)': {
            marginLeft: 0
        }
    },
    mainBlock: {
        marginLeft: 255,
        padding: 25,
        height: '100vh',
        // position: 'sticky',
        // top: '0px',
        overflowY: 'auto',
        scrollbarWidth: 'none',
        '@media (max-width: 1080px)': {
            marginLeft: 0
        }
    },
    contentBlock: {
        marginTop: 42
    }
});

function PrivateSection() {
    const theme = useTheme();
    const classes = useStyles({ theme });
    const loading = useSelector(state => state.auth.showLoading)

    return (
        <SidebarContext>
            <Row className={classes.container}>
                <SidebarComponent />
                <Column flexGrow={1} className={classes.mainBlock}>
                    <HeaderComponent />
                    <div className={classes.contentBlock}>
                        <PrivateRoutes />
                        {loading && <Loader/>}
                    </div>
                </Column>
            </Row>
        </SidebarContext>
    );
}

export default PrivateSection;
