import React from 'react'

const AboutItem = ({image, description}) => {
    const className = {
        itemDiv: 'item-div',
        divName: 'icon-div',
        alt: 'icon',
        imageName: 'img-fluid',
        textName: 'text'
    }
    return (
        <div className={className.itemDiv}>
            <div className={className.divName}>
                <img src={image} alt={className.alt} className={className.imageName}/>
            </div>
            <p className={className.textName}>{description}</p>
        </div>
    )
}

export default AboutItem
