const mediaIcons = [
	'fa-facebook-f',
	'fa-twitter',
	'fa-linkedin-in',
	'fa-instagram',
];

const contact = {
	email: 'info@checkmanng.com',
	phone: '+234 809 129 0000',
};

let date = new Date();
let year = date.getFullYear();
const message = {
	scope: ` Checkman Tehnologies is an African Auto Tech 
            Company dedicated to creating solutions for Modern Auto care`,
	text: `Copyright © ${year} Checkman Technologies`,
};

export default {
	mediaIcons,
	contact,
	message,
};
