import axios from "axios";
import { CARFAX_LOADING, CHECKMAN_ERROR, CHECKMAN_LOADING, CHECKMAN_LOADING_HISTORY, CHECKMAN_SAMPLE_ERROR, CHECKMAN_SUCCESS, CTA_ERROR, FULLSCREEN, MINISCREEN } from "../appRedux/actions/actionTypes";
import store from "../appRedux/utils/store";
import api from "./api";

export const checkHistory = (vinNumber) => {
    return api.get(`/check?vin=${vinNumber}`);
}

export const vehicleHistory = () => {
    const state = store.getState();
    const userId = state.auth.authUser.data.user.id;
    return api.get(`/users/${userId}/history`);
}
export const reportAvailability = (vinNumber) => {
    return api.get(`/reportavailability?vin=${vinNumber}`);
}

export const formatCheckmanError = (error) => {
    return error;
}

export const failedCheck = (message) => {
    return {
        type: CHECKMAN_ERROR,
        payload: message
    }
}

export const failedCheckSample = (message) => {
    return {
        type: CHECKMAN_SAMPLE_ERROR,
        payload: message
    }
}

export const failedCTA = (message) => {
    return {
        type: CTA_ERROR,
        payload: message
    }
}

export const formatCheckmanSuccess = (success) => {
    return success
}

export const approvedCheck = (message) => {
    return {
        type: CHECKMAN_SUCCESS,
        payload: message
    }
}

export const checkmanLoading = (status) => {
    return {
        type: CHECKMAN_LOADING,
        payload: status
    }
}
export const carfaxVinLoading = (status) => {
    return {
        type: CARFAX_LOADING,
        payload: status
    }
}
export const checkmanLoadingHistory = (status) => {
    return {
        type: CHECKMAN_LOADING_HISTORY,
        payload: status
    }
}
export const toViewToFullScreen = (status) => {
    return {
        type: MINISCREEN,
        payload: status
    }
}