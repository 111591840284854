import React, { useState } from 'react';
import Label from './Label';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import './../../../styles/Login/index.css';
import { signupAction } from '../../../appRedux/actions/signupAction';
import { Container, Form, Button, Col, Row } from 'react-bootstrap';
import {
	FETCH_ERROR,
	FETCH_SUCCESS,
	FETCH_MESSAGE,
	SIGNUP_USER,
} from './../../../appRedux/actions/actionTypes';
import {
	failedAction,
	formatError,
	formatSuccess,
	userSignUp,
	loadingAction,
	successAction,
	tokenExpirationCheck,
} from '../../../services/auth';
import signupReducer from '../../../appRedux/reducers/signupReducer';
import Loader from '../../loader/Loader';
import { signupLoading } from '../../../services/auth';
import GoogleLogin from 'react-google-login';
import GoogleLoginComponent from './GoogleLoginComponent';
import { useLogout } from '../../../hooks/useLogout';

const SignUp = () => {
	const [userFullName, setFullName] = useState('');
	const [userName, setUserName] = useState('');
	const [userMobile, setUserMobile] = useState('');
	const [userEmail, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [confirmPassword, setConPassword] = useState('');
	const [success, setSuccess] = useState(null);
	const errorMessage = useSelector((state) => state.signup.errorMessage);
	const successMessage = useSelector((state) => state.signup.successMessage);
	const loading = useSelector((state) => state.signup.showLoading);
	const dispatch = useDispatch();
	const { logout } = useLogout();

	const handleSubmit = (e) => {
		e.preventDefault();
		dispatch(signupLoading(true));
		const user = {
			userFullName,
			userName,
			userMobile,
			userEmail,
			password,
			confirmPassword,
		};
		// props.signupAction(user)
		userSignUp(user)
			.then(({ data }) => {
				console.log('user:', data);
				if ((data.status = 'success')) {
					dispatch(signupLoading(false));
					dispatch({ type: FETCH_SUCCESS });
					dispatch({
						type: FETCH_MESSAGE,
						payload: data.message,
					});
					console.log('payload: data success ', data.message);
					localStorage.setItem('token', data.token);

					// tokenExpirationCheck(
					// 	dispatch,
					// 	data.data.user.linkTokenExpires * 8000,
					// );
					const successMessage = formatSuccess(data.message);
					dispatch(successAction(successMessage));
					logout();
					// setSuccess(data.message)
				} else {
					console.log('payload: data error ', data.message);
				}
			})
			.catch((error) => {
				dispatch(signupLoading(false));
				console.log(error.response);
				const errorMessage = formatError(error.response.data.message);
				dispatch(failedAction(errorMessage));
			});
	};

	const onSuccess = (response) => {
		console.log('Logged in');
		console.log(response.profileObj);
		console.log(response);
		localStorage.setItem('goggleacct', response.profileObj);
	};

	const onFailure = (response) => {
		console.log(response);
		console.log('Failed');
	};

	return (
		<Container className="login-con">
			<Row>
				<Col></Col>
				<Col sm={4}>
					<h2>SignUp</h2>
					<Form onSubmit={handleSubmit}>
						<Form.Group className={Label.classes.formdiv}>
							<Form.Control
								type="text"
								placeholder="Fullname"
								className="form-control"
								value={userFullName}
								onChange={(e) => setFullName(e.target.value)}
							/>
						</Form.Group>

						<Form.Group className={Label.classes.formdiv}>
							<Form.Control
								type="text"
								placeholder="Username"
								className="form-control"
								value={userName}
								onChange={(e) => setUserName(e.target.value)}
							/>
						</Form.Group>
						<Form.Group className={Label.classes.formdiv}>
							<Form.Control
								type="tel"
								placeholder="Phone no"
								className="form-control"
								value={userMobile}
								onChange={(e) => setUserMobile(e.target.value)}
							/>
						</Form.Group>
						<Form.Group className={Label.classes.formdiv}>
							<Form.Control
								type="email"
								placeholder="Email"
								className="form-control"
								value={userEmail}
								onChange={(e) => setEmail(e.target.value)}
							/>
						</Form.Group>
						<Form.Group className={Label.classes.formdiv}>
							<Form.Control
								type="password"
								placeholder="Password"
								className="form-control"
								value={password}
								onChange={(e) => setPassword(e.target.value)}
							/>
						</Form.Group>
						<Form.Group className={Label.classes.formdiv}>
							<Form.Control
								type="password"
								placeholder="Confirm password"
								className="form-control"
								value={confirmPassword}
								onChange={(e) => setConPassword(e.target.value)}
							/>
						</Form.Group>

						{errorMessage && <div className="error">{errorMessage}</div>}
						{successMessage && <div className="success">{successMessage}</div>}

						<Form.Group className="form-group">
							<Button
								variant="primary"
								type="submit"
								className="btn"
								onSubmit={handleSubmit}
							>
								SIGNUP
							</Button>
						</Form.Group>
					</Form>

					{/* <hr /> */}
					{/* <div className="text-center googlediv">
						<GoogleLoginComponent title="Signup with Google" />
					</div> */}

					{loading && <Loader />}
				</Col>
				<Col></Col>
			</Row>
		</Container>
	);
};

SignUp.propTypes = {
	signupAction: PropTypes.func.isRequired,
};

export default connect(null, { userSignUp })(SignUp);
