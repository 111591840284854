import React from 'react'
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'react-jss';
import Theme from './../../Db/resources/theme';
import Routes from './../../Db/routes';
import './../../Db/index.css';
// import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
const Dashboard = () => {
    return (
        <div>
            <ThemeProvider theme={Theme}>
                <Router>
                    <Routes />
                </Router>
            </ThemeProvider>
        </div>
    )
}

export default Dashboard
