import React from 'react';
import { Container, Row, Col, Nav } from 'react-bootstrap';
import icon1 from './../../../../assests/Checkman Web Assets/Checkman-Logo---White.png';
import icon2 from './../../../../assests/Checkman Web Assets/carfax-logo.png';
import './../../../../styles/Homepage/footer.css';
import items from './items';

const Footer = () => {
	return (
		<Container fluid className="footer">
			<Container>
				<Row>
					<Col sm={6}>
						<div className="footer-brand">
							<img src={icon1} alt="icon" className="img-fluid" />
						</div>
						<p className="ft-txt"> {items.message.scope}</p>
						<div className="social-icons-div">
							<i className={`fab ${items.mediaIcons[0]}`}></i>
							<i className={`fab ${items.mediaIcons[1]}`}></i>
							<i className={`fab ${items.mediaIcons[2]}`}></i>
							<i className={`fab ${items.mediaIcons[3]}`}></i>
						</div>
					</Col>
					<Col sm={3} className="ft-links">
						<h3>Reports</h3>
						<Nav defaultActiveKey="/home" className="flex-column">
							<Nav.Link href="/home">VIN Flood Report</Nav.Link>
							<Nav.Link eventKey="link-1">Japanese VIN Check</Nav.Link>
							<Nav.Link eventKey="link-2">Theft/Odometer Report</Nav.Link>
							<Nav.Link eventKey="link-1">Accident/Auctionn Report</Nav.Link>
							<Nav.Link eventKey="link-1">Photo Report</Nav.Link>
						</Nav>
					</Col>
					<Col sm={3} className="ft-links">
						<h3>Contact Us</h3>
						<Container className="ft-mail">
							<p>{items.contact.email}</p>
							<p>{items.contact.phone}</p>
						</Container>
						<Container className="ft-ld-part">
							<h3>Lead Partner</h3>
							<div className="footer-brand">
								<img src={icon2} alt="icon" className="img-fluid" />
							</div>
						</Container>
					</Col>
				</Row>
				<p className="text-center">
					<b>{items.message.text}</b>
				</p>
			</Container>
		</Container>
	);
};

export default Footer;
