import React from 'react'

const ReviewComponent = (props) => {
    const classes = {
        divName: 'img-div',
        imgName: 'img-fluid',
        altName: 'image',
        reviewDiv: 'caption'
    };
    return (
        <>
            <div className={classes.divName}>
                <img src={props.src} alt={classes.altName} className={classes.imgName}/>
            </div>
            <div className={`${classes.reviewDiv} text-center`}>
                <h3>{props.userName}</h3>
                <p>{props.review}</p>
            </div>
        </>
    );
}

export default ReviewComponent
