import React from 'react'
import { Row, Col } from 'react-bootstrap';
import AboutItem from './AboutItem';
import ImgOne from "./../../../../assests/Checkman Web Assets/Icon-001-vehicle-registration.png"
import  imgTwo from "./../../../../assests/Checkman Web Assets/Icon-002-Title-Information.png"
import  imgThree from "./../../../../assests/Checkman Web Assets/Icon-003-Odometer-Readings.png"
import  imgFour from "./../../../../assests/Checkman Web Assets/Icon-004-Lemon-History.png"
import  imgFive from "./../../../../assests/Checkman Web Assets/Icon-005-Total-Loss-Information.png"
import  imgSix from "./../../../../assests/Checkman Web Assets/Icon-006-Stolen-&-Recovery-Info-Information.png"
import  imgSeven from "./../../../../assests/Checkman Web Assets/Icon-007-Structural-Damage.png"
import  imgEight from "./../../../../assests/Checkman Web Assets/Icon-008-Accidennt-Indicators.png"
import  imgNine from "./../../../../assests/Checkman Web Assets/Icon-009-Service-&-Repair-Info.png"
import  imgTen from "./../../../../assests/Checkman Web Assets/Icon-010-Vehicle-Usage.png"
import  imgEleven from "./../../../../assests/Checkman Web Assets/Icon-011-Vehicle-Recall.png"
import  imgTwelve from "./../../../../assests/Checkman Web Assets/Icon-012-Flood-Record.png"

const AboutImg = () => {
    return (
        <div>
            <Row>
                <Col sm={2}>
                    <AboutItem 
                        image = {ImgOne}
                        description = 'Vehicle Registration Information'
                    />
                </Col>
                <Col sm={2}>
                    <AboutItem 
                        image = {imgTwo}
                        description = 'Title information, including salvaged or junked titles'
                    />
                </Col>
                <Col sm={2}>
                    <AboutItem 
                        image = {imgThree}
                        description = 'Odometer readings'
                    />
                </Col>
                <Col sm={2}>
                    <AboutItem 
                        image = {imgFour}
                        description = 'Lemon History'
                    />
                </Col>
                <Col sm={2}>
                    <AboutItem 
                        image = {imgFive}
                        description = 'Total Information'
                    />
                </Col>
                <Col sm={2}>
                    <AboutItem 
                        image = {imgSix}
                        description = 'Stolen & Recovery Information'
                    />
                </Col>
            </Row>
            <Row>
                <Col sm={2}>
                    <AboutItem 
                        image = {imgSeven}
                        description = 'Structural Damage'
                    />
                </Col>
                <Col sm={2}>
                    <AboutItem 
                        image = {imgEight}
                        description = 'Accident indicators, such as airbag deployment'
                    />
                </Col>
                <Col sm={2}>
                    <AboutItem 
                        image = {imgNine}
                        description = 'Service & Repair Information'
                    />
                </Col>
                <Col sm={2}>
                    <AboutItem 
                        image = {imgTen}
                        description = 'Vehicle usage (taxi, rental, lease, etc.) Information'
                    />
                </Col>
                <Col sm={2}>
                    <AboutItem 
                        image = {imgEleven}
                        description = 'Vehicle Recall History'
                    />
                </Col>
                <Col sm={2}>
                    <AboutItem 
                        image = {imgTwelve}
                        description = 'Flood Record/ History'
                    />
                </Col>
            </Row>
        </div>
    )
}

export default AboutImg

