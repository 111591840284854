import React from 'react'
import { Carousel, Col, Container, Row } from 'react-bootstrap'
import './../../../../styles/Homepage/CTA.css';
import ahmed from "../../../../assests/img/ahmed.jpg"
import chukwudi from "../../../../assests/img/chukwudi.jpg"
import tosin from "../../../../assests/img/tosin.jpg"

import ReviewComponent from './ReviewComponent';

const Reviews = () => {
    return (
        <Container className="review-con">
            <h1 className="text-center">User Reviews</h1>
            <Carousel fade>
                <Carousel.Item>
                    <Row>
                        <Col sm={4}>
                            <ReviewComponent
                                src = {chukwudi}
                                userName = 'Chukwudi'
                                review = {`I’m glad I used checkman to verify the type of vehicle I nearly bought! 
                                I checked 5 vehicles till I was satisfied. I would have lost N5M!`}
                            />
                        </Col>
                        <Col sm={4}>
                            <ReviewComponent
                                src = {tosin}
                                userName = 'Tosin'
                                review = {`Checkman has the most competitive pricing. 
                                It makes it easier for me to check for my car records`}
                            />
                        </Col>
                        <Col sm={4}>
                            <ReviewComponent
                                src = {ahmed}
                                userName = 'Ahmed'
                                review = {`I used checkman to check the history of the vehicle
                                            I currently own to understand some things about the car
                                             and the results I got have been very helpful`}
                            />
                        </Col>
                    </Row>
                </Carousel.Item>
                {/* <Carousel.Item>
                    <Row>
                        <Col sm={4}>
                            <ReviewComponent
                                src = {img}
                                userName = 'Jason Creed'
                            />
                        </Col>
                        <Col sm={4}>
                            <ReviewComponent
                                src = {img}
                                userName = 'Jason Creed'
                            />
                        </Col>
                        <Col sm={4}>
                            <ReviewComponent
                                src = {img}
                                userName = 'Jason Creed'
                            />
                        </Col>
                    </Row>
                </Carousel.Item> */}
            </Carousel>
        </Container>

    )
}

export default Reviews
