import React, { useState } from 'react';
import { Col, Row, Button, Modal, Form } from 'react-bootstrap';
import { Container } from 'react-bootstrap';
import CardItem from './CardItem';
import './../../../../styles/Homepage/prices.css';
import store from '../../../../appRedux/utils/store';
import { useHistory } from 'react-router';
import { connect, useDispatch, useSelector } from 'react-redux';
import Loader from '../../../loader/Loader';
import {
	failedActionWallet,
	fundWalletLoading,
	wallet,
} from '../../../../services/wallet';
import {
	AUTHENTICATED,
	FUND_WALLET,
	LOGOUT,
	WALLET,
} from '../../../../appRedux/actions/actionTypes';
import { fundWalletError, logoutLoading } from '../../../../services/auth';

const Prices = () => {
	const state = store.getState();
	const token = localStorage.getItem('token') || state.auth.token;
	const history = useHistory();
	const dispatch = useDispatch();
	const loading = useSelector((state) => state.wallet.showLoading);
	const errorMessage = useSelector((state) => state.wallet.errorMessage);
	const [value, setValue] = useState('4500');
	// const [valueTwo, setValueTwo] = useState('20000');
	const [valueTwo, setValueTwo] = useState('10000');
	const [valueThree, setValueThree] = useState('38000');

	const [payStackURL, setPaystackURL] = useState('');
	const [paystack, setPaystack] = useState(false);
	const closePaytsackModal = () => setPaystack(false);
	const showPaystackModal = () => setPaystack(true);

	const routeToPaystack = (e) => {
		e.preventDefault();
		setPaystack(false);
	};

	const type = {
		one: '1 Report',
		// two: '5 Reports',
		two: 'A Report',
		three: '10 Reports',
	};
	const price = {
		one: '4,500',
		// two: '4,000',
		two: '10,000',
		three: '3,800',
	};

	const [show, setShow] = useState(false);
	const [showTwo, setShowTwo] = useState(false);
	const [showThree, setShowThree] = useState(false);

	const handleClose = () => setShow(false);
	const handleCloseTwo = () => setShowTwo(false);
	const handleCloseThree = () => setShowThree(false);

	const handleShow = () => setShow(true);
	const handleShowTwo = () => setShowTwo(true);
	const handleShowThree = () => setShowThree(true);

	const logout = () => {
		dispatch({ type: LOGOUT });
		dispatch(logoutLoading(true));
		localStorage.removeItem('user');
		localStorage.removeItem('token');
		localStorage.removeItem('wallet');
		localStorage.removeItem('id');
		localStorage.removeItem('fax');
		dispatch({ type: AUTHENTICATED, payload: false });
		setTimeout(() => {
			history.push('/login');
			window.location.reload();
		}, 4000);
	};

	const handleSubmit = (e) => {
		// e.preventDefault();
		if (token) {
			dispatch(fundWalletLoading(true));
			handleClose();
			setValue('4500');
			console.log(value);
			dispatch({ type: WALLET });
			wallet({ value })
				.then((data) => {
					console.log(data);
					const URL = data.data.data.checkOutURL;
					setPaystackURL(URL);
					dispatch(fundWalletLoading(false));
					showPaystackModal();
					dispatch({ type: FUND_WALLET, payload: data });
				})
				.catch((error) => {
					console.log(error);
					console.log(error.response);
					const errorMessage = fundWalletError(error.response.data.message);
					dispatch(failedActionWallet(errorMessage));
					setTimeout(() => {
						dispatch(failedActionWallet(''));
					}, 3000);
					if (error.response.data.message == 'jwt malformed') {
						window.location.reload();
					}
					if (error.response.data.message == 'jwt expired') {
						logout();
					}
					dispatch(fundWalletLoading(false));
				});
		} else {
			const errorM = fundWalletError('Kindly login to complete purchase');
			dispatch(failedActionWallet(errorM));
			setTimeout(() => {
				dispatch(failedActionWallet(''));
			}, 3000);
		}
	};
	const handleSubmitTwo = (e) => {
		// e.preventDefault();
		if (token) {
			dispatch(fundWalletLoading(true));
			handleCloseTwo();
			// setValueTwo('17500');
			setValueTwo('10000');
			console.log(valueTwo);
			dispatch({ type: WALLET });
			wallet({ value: valueTwo })
				.then((data) => {
					console.log(data);
					const URL = data.data.data.checkOutURL;
					setPaystackURL(URL);
					dispatch(fundWalletLoading(false));
					showPaystackModal();
					dispatch({ type: FUND_WALLET, payload: data });
				})
				.catch((error) => {
					console.log(error);
					console.log(error.response);
					const errorMessage = fundWalletError(error.response.data.message);
					dispatch(failedActionWallet(errorMessage));
					setTimeout(() => {
						dispatch(failedActionWallet(''));
					}, 3000);
					if (error.response.data.message == 'jwt malformed') {
						window.location.reload();
					}
					dispatch(fundWalletLoading(false));
				});
		} else {
			const errorM = fundWalletError('Kindly login to complete purchase');
			dispatch(failedActionWallet(errorM));
			setTimeout(() => {
				dispatch(failedActionWallet(''));
			}, 3000);
		}
	};
	const handleSubmitThree = (e) => {
		// e.preventDefault();
		if (token) {
			dispatch(fundWalletLoading(true));
			handleCloseThree();
			setValueThree('30000');
			console.log(valueThree);
			dispatch({ type: WALLET });
			wallet({ value: valueThree })
				.then((data) => {
					console.log(data);
					const URL = data.data.data.checkOutURL;
					setPaystackURL(URL);
					dispatch(fundWalletLoading(false));
					showPaystackModal();
					dispatch({ type: FUND_WALLET, payload: data });
				})
				.catch((error) => {
					console.log(error);
					console.log(error.response);
					const errorMessage = fundWalletError(error.response.data.message);
					dispatch(failedActionWallet(errorMessage));
					setTimeout(() => {
						dispatch(failedActionWallet(''));
					}, 3000);
					if (error.response.data.message == 'jwt malformed') {
						window.location.reload();
					}
					dispatch(fundWalletLoading(false));
				});
		} else {
			const errorM = fundWalletError('Kindly login to complete purchase');
			dispatch(failedActionWallet(errorM));
			setTimeout(() => {
				dispatch(failedActionWallet(''));
			}, 3000);
		}
	};

	return (
		<Container className="text-center price-con">
			{loading && <Loader />}
			<h1>Pricing</h1>
			<Row>
				<Col sm={3}>
					{/* <CardItem
                            type = {type.one}
                            price = {price.one}
                            package = 'Full Comprehensive report'
                            path = '#'
                            onClick = {() => handleSubmit()}
                        /> */}
				</Col>
				<Col sm={6} className="middle-col">
					<CardItem
						className="middle-card"
						recommend="BEST PRICE"
						type={type.two}
						price={price.two}
						package="Full Comprehensive report"
						// specification = 'per report'
						path="#"
						onClick={() => handleSubmitTwo()}
					/>
				</Col>
				<Col sm={3}>
					{/* <CardItem
                            type = {type.three}
                            price = {price.three}
                            package = 'Full Comprehensive report'
                            path = '#'
                            onClick = {() => handleSubmitThree()}
                        /> */}
				</Col>
			</Row>
			{/* <h2>Bulk price coming soon</h2> */}
			{errorMessage && <small className="error">{errorMessage}</small>}

			<Modal show={show} onHide={handleClose} size="sm" className="modal">
				<Modal.Body
					style={{
						padding: '10px',
					}}
				>
					<h4
						style={{
							fontWeight: 'bolder',
							fontSize: '1.2rem',
							padding: '10px',
							textAlign: 'center',
						}}
					>
						Buy for
					</h4>
					<Form onSubmit={handleSubmit}>
						<Form.Group>
							<Form.Control
								type="text"
								value={value}
								placeholder={value}
								onChange={(e) => setValue(e.target.value)}
								style={{
									marginTop: '4px',
									textAlign: 'center',
								}}
							/>
						</Form.Group>
						<Form.Group>
							<Button
								variant="secondary"
								type="submit"
								style={{
									border: '1px solid black',
									backgroundColor: 'black',
									color: 'white',
									width: '100%',
									marginTop: '4px',
								}}
								onClick={() => handleClose()}
							>
								Enter
							</Button>
						</Form.Group>
					</Form>
				</Modal.Body>
			</Modal>
			<Modal show={showTwo} onHide={handleCloseTwo} size="sm">
				<Modal.Body>
					<h4
						style={{
							fontWeight: 'bolder',
							fontSize: '1.2rem',
							padding: '10px',
							textAlign: 'center',
						}}
					>
						Buy for
					</h4>
					<Form onSubmit={handleSubmitTwo}>
						<Form.Group>
							<Form.Control
								type="text"
								value={valueTwo}
								placeholder={valueTwo}
								onChange={(e) => setValueTwo(e.target.value)}
								style={{
									marginTop: '4px',
									textAlign: 'center',
								}}
							/>
						</Form.Group>
						<Form.Group>
							<Button
								variant="secondary"
								type="submit"
								style={{
									border: '1px solid black',
									backgroundColor: 'black',
									color: 'white',
									width: '100%',
									marginTop: '4px',
								}}
								onClick={() => handleCloseTwo()}
							>
								Enter
							</Button>
						</Form.Group>
					</Form>
				</Modal.Body>
			</Modal>
			<Modal show={showThree} onHide={handleCloseThree} size="sm">
				<Modal.Body>
					<h4
						style={{
							fontWeight: 'bolder',
							fontSize: '1.2rem',
							padding: '10px',
							textAlign: 'center',
						}}
					>
						Buy for
					</h4>
					<Form onSubmit={handleSubmitThree}>
						<Form.Group>
							<Form.Control
								type="text"
								value={valueThree}
								placeholder={valueThree}
								onChange={(e) => setValueThree(e.target.value)}
								style={{
									marginTop: '4px',
									textAlign: 'center',
								}}
							/>
						</Form.Group>
						<Form.Group>
							<Button
								variant="secondary"
								type="submit"
								style={{
									border: '1px solid black',
									backgroundColor: 'black',
									color: 'white',
									width: '100%',
									marginTop: '4px',
								}}
								onClick={() => handleCloseThree()}
							>
								Enter
							</Button>
						</Form.Group>
					</Form>
				</Modal.Body>
			</Modal>
			<Modal
				show={paystack}
				onHide={closePaytsackModal}
				size="sm"
				className="modal-div-paystack"
			>
				<Modal.Body>
					<h4
						style={{
							fontWeight: 'bolder',
							fontSize: '1.2rem',
							padding: '10px',
							textAlign: 'center',
						}}
					>
						Continue to fund wallet?
					</h4>

					<Form onSubmit={routeToPaystack}>
						<Form.Group
							className="form-group"
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								textAlign: 'center',
							}}
						>
							<a
								href={payStackURL}
								target="_blank"
								className="paylink"
								style={{
									border: '1px solid black',
									backgroundColor: 'black',
									color: 'white',
									width: '80%',
									marginTop: '4px',
									textDecoration: 'none',
									padding: '4px',
									margin: 'auto',
								}}
							>
								Continue
							</a>
						</Form.Group>
					</Form>
				</Modal.Body>
			</Modal>
		</Container>
	);
};

// export default Prices
export default connect(null, { wallet, fundWalletLoading })(Prices);
