import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import icon1 from './../../../assests/Checkman Web Assets/Checkman-Logo---color.png';
import icon2 from './../../../assests/Checkman Web Assets/carfax-logo.png';
import icon3 from './../../../assests/Checkman Web Assets/autocheck-logo.png';
import icon4 from './../../../assests/Checkman Web Assets/COPART-LOGO.png';
import icon5 from './../../../assests/Checkman Web Assets/NMVTIS-Logo.png';
import './../../../styles/Homepage/partner.css';


const Partners = () => {
    return (
        <Container fluid className="partner-con text-center">
            <Container>
                <h2>Get the most comprehensive vehicle check on the market!</h2>
                <div className="iconColored-div">
                    <img src={icon1} alt="icon" className="img-fluid"/>
                </div>
                <h1 className="plus">+</h1>
                <Row className="text-cente partnerIconDiv">
                    <Col>
                        <div className="icon-div nb">
                            <img src={icon2} alt="icon" className="img-fluid"/>
                        </div>
                    </Col>
                    <Col>
                        <div className="icon-div">
                            <img src={icon3} alt="icon" className="img-fluid"/>
                        </div>
                    </Col>
                    <Col>
                        <div className="icon-div">
                            <img src={icon4} alt="icon" className="img-fluid"/>
                        </div>
                    </Col>
                    <Col>
                        <div className="icon-div">
                            <img src={icon5} alt="icon" className="img-fluid"/>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}

export default Partners
