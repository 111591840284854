import {
    INIT_URL, 
    USER_DATA,
    FORGOT_MAIL_SENT,
    USER_TOKEN_SET,
    USER_VERIFIED,
    MAIL_RESENT,
    PASSWORD_RESET,
    UPDATE_PASSWORD,
    UPDATE_PASSWORD_FAILED,
    FETCH_ERROR,
    FETCH_SUCCESS,
    UPDATE_PASSWORD_LOADING,
    PASSWORD_RESET_LOADING,
    AUTHENTICATED,
    LOGOUT_LOADING,
    LOGOUT,
    FORGOT_MAIL_ERROR
} from "../actions/actionTypes";

const initialState = {
    token: localStorage.getItem("token"),
    verified: null,
    authenticated: false,
    authUser: JSON.parse(localStorage.getItem('user')),
    successMessage: '',
    errorMessage: '',
    showLoading: false
}

export default (state = initialState, action) => {
    switch(action.type) {

        case USER_DATA: {
            return {
                ...state,
                authUser: action.payload,
            };
        }

        case FORGOT_MAIL_SENT: {
            return {
                ...state,
                successMessage: action.payload,
                errorMessage: '',
            }
        };
        case FORGOT_MAIL_ERROR: {
            return {
                ...state,
                successMessage: '',
                errorMessage: action.payload
            }
        }

        case PASSWORD_RESET: {
            return {
                ...state,
                payload: action.payload
            }
        };
        case UPDATE_PASSWORD: 
            return {
                ...state,
                payload: action.payload,
            }
        case FETCH_ERROR:
            return{
                ...state,
                payload: action.payload,
                successMessage: '',
                errorMessage: action.payload
            }
        case FETCH_SUCCESS: 
            return{
                ...state,
                payload: action.payload,
                successMessage: action.payload,
                errorMessage: '',
            }
        case UPDATE_PASSWORD_LOADING:
            return {
                ...state,
                showLoading: action.payload
            }
        case PASSWORD_RESET_LOADING:
            return {
                ...state,
                showLoading: action.payload
            }
        case LOGOUT_LOADING: 
            return {
                ...state,
                showLoading: action.payload
            }
        case LOGOUT: {
            return {
                ...state
            }
        }
        case USER_TOKEN_SET: {
            return {
                ...state,
                token: action.payload,
            }
        };

        case USER_VERIFIED: {
            return {
                ...state,
                verified: action.payload,
            }
        };
        case AUTHENTICATED: 
        return {
            ...state,
            authenticated: action.payload
        }

        case MAIL_RESENT: {
            return {
                ...state,
                mail_sent: action.payload
            }
        };

        default: return state;
    }
}