import React, { Suspense, lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import SLUGS from './../resources/slugs';
import LoadingComponent from './../components/loading/LoadingComponent';
import Home from './../../components/Pages/Index';
import Login from '../../components/Pages/Login';
import Fax from '../../components/Pages/Fax';
// import WalletComponents from './wallet';

const DashboardComponent = lazy(() => import('./dashboard'));
const WalletComponent = lazy(() => import('./wallet'));
const FundWallet = lazy(() => import('./wallet/FundWallet'));
const Transaction = lazy(() => import('./wallet/Transactions'));
// const Invoice = lazy(() => import('./template/wallet/Invoice'));
const CheckmanComponents = lazy(() => import('./checkman'));
const PreviousCheck = lazy(() => import('./checkman/PreviousCheck'));
const Setting = lazy(() => import('./settings/index'));
// const Carfax = lazy(() => import('./checkman/Carfax'));
// const Home = lazy(() => import('./../../components/Pages/Index'));


function PrivateRoutes() {
    return (
        <Suspense fallback={<LoadingComponent loading />}>
            <Switch>
                <Route exact path={SLUGS.dashboard} component={DashboardComponent} />
                <Route exact path={SLUGS.fundWallet} component={FundWallet} />
                <Route exact path={SLUGS.transactionHistory} component={Transaction} />
                <Route exact path={SLUGS.wallet} component={WalletComponent} />
                {/* <Route exact path={SLUGS.invoice} component={Invoice} /> */}
                <Route exact path={SLUGS.vehicleHistory} component={CheckmanComponents} />
                <Route exact path={SLUGS.newCheck}  component={CheckmanComponents} />
                <Route exact path={SLUGS.prevCheck} component={PreviousCheck} />
                <Route exact path={SLUGS.settings} component={Setting} />
                <Route exact path='/carfax' component={Fax}/>
                <Route exact path="/" component={Home}/>
                <Route path="/login" component={Login}/>
                {/* <Redirect to={SLUGS.dashboard} /> */}
            </Switch>
        </Suspense>
    );
}

export default PrivateRoutes;
