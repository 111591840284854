import api from "./api";
import store from "../appRedux/utils/store";
import axios from "axios";
import { FETCH_WALLET_ERROR, USER_TRANSACTION_ERROR, USER_TRANSACTION_SUCCESS, WALLET_LOADING, WALLET_LOADING_HISTORY } from "../appRedux/actions/actionTypes";
import { useSelector } from "react-redux";


export const wallet = ({value}) => {
    const state = store.getState();
    const userId = state.auth.authUser.data.user.id;
    return api.post(`/users/${userId}/fundwallet`, {amount: value})
}

export const transaction = () => {
    const state = store.getState();
    const userId = state.auth.authUser.data.user.id;
    return api.get(`/users/${userId}/transactions`)
}

export const returnWallet = () => {
    const state = store.getState();
    const userId = state.auth.authUser.data.user.id;
    return api.get(`/users/${userId}/returnwallet`)
}

export const getInvoice = (id) => {
    const state = store.getState();
    const userId = state.auth.authUser.data.user.id;
    return api.get(`/users/${userId}/transactions/${id}`)
}

export const formatWalletError = (error) => {
    return error;
}

export const failedTransaction = (message) => {
    return {
        type: USER_TRANSACTION_ERROR,
        payload: message
    }
}

export const formatWalletSuccess = (success) => {
    return success
}

export const approvedTransaction = (message) => {
    return {
        type: USER_TRANSACTION_SUCCESS,
        payload: message
    }
}
export const walletLoading = (status) => {
    return {
        type: WALLET_LOADING_HISTORY,
        payload: status
    }
}
export const fundWalletLoading = (status) => {
    return {
        type: WALLET_LOADING,
        payload: status
    }
}
export const failedActionWallet = (message) => {
    return {
        type: FETCH_WALLET_ERROR,
        payload: message
    }
}