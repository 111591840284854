export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGOUT= 'LOGOUT';
export const INIT_URL = 'INIT_URL';
export const USER_DATA = 'user_data';
export const AUTHENTICATED = 'AUTHENTICATED';

export const EMAIL_VERIFIED = 'EMAIL_VERIFIED';

export const PASSWORD_RESET = 'PASSWORD_RESET';
export const PASSWORD_RESET_LOADING = 'PASSWORD_RESET_LOADING';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const UPDATE_PASSWORD_LOADING = 'UPDATE_PASSWORD_LOADING';
export const USER_TOKEN_SET = 'user_token_set';
export const USER_VERIFIED = 'USER_VERIFIED'

export const FETCH_SUCCESS = 'FETCH_SUCCESS';
export const FETCH_ERROR = 'FETCH_ERROR';
export const FETCH_WALLET_ERROR = 'FETCH_WALLET_ERROR';
export const FETCH_MESSAGE = 'FETCH_MESSAGE';

export const LOADING = 'LOADING';
export const LOGIN_LOADING = 'LOGIN_LOADING'; 
export const LOGOUT_LOADING = 'LOGOUT_LOADING';
export const SIGNUP_LOADING = 'SIGNUP_LOADING'; 
export const CHECKMAN_LOADING = 'CHECKMAN_LOADING';
export const CHECKMAN_LOADING_HISTORY = 'CHECKMAN_LOADING_HISTORY'; 
export const CARFAX_LOADING = 'CARFAX_LOADING'
export const WALLET_LOADING = 'WALLET_LOADING'; 
export const WALLET_LOADING_HISTORY = 'WALLET_LOADING_HISTORY'; 

export const TOKEN_TIMER = 'TOKEN_TIMER';
export const TOKEN_EXPIRED = 'TOKEN_EXPIRED';

export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_MAIL_SENT = 'FORGOT_MAIL_SENT';
export const FORGOT_MAIL_ERROR = 'FORGOT_MAIL_ERROR';
export const MAIL_RESENT = 'MAIL_RESENT';

export const WALLET = 'WALLET';
export const FETCH_BALANCE = 'FETCH_BALANCE';
export const FUND_WALLET = 'FUND_WALLET';
export const PAYSTACK = 'PAYSTACK';
export const PAYSTACK_URL = 'PAYSTACK_URL';
export const PACKAGE = 'PACKAGE';
export const USER_TRANSACTION = 'USER_TRANSACTION';
export const USER_TRANSACTION_SUCCESS = 'USER_TRANSACTION_SUCCESS';
export const USER_TRANSACTION_ERROR = 'USER_TRANSACTION_ERROR';

export const VEHICLE_LOOKUP = 'Vehicle Lookup';
export const CHECKMAN_SUCCESS = 'CHECKMAN_SUCCESS';
export const CHECKMAN_ERROR = 'CHECKMAN_ERROR';
export const CHECKMAN_SAMPLE_ERROR = 'CHECKMAN_SAMPLE_ERROR';
export const CTA_ERROR = 'CTA_ERROR';
export const CTA_SUCCESS = 'CTA_SUCCESS';
export const CHECKMAN_HISTORY = 'CHECKMAN_HISTORY';
export const CHECKMAN_HISTORY_SUCCESS = 'CHECKMAN_HISTORY_SUCCESS';
export const CHECKMAN_HISTORY_ERROR = 'CHECKMAN_HISTORY_ERROR';
export const MINISCREEN = 'MINISCREEN';
